
html {
  font-size: 100%;
}

body *::selection {
  background: rgba(0, 160, 176, 0.8);
  color: white;
}



.clear {
  width: 100%;
  height: 0;
  border: none;
  clear: both;
  margin: 0;
  padding: 0;
}

a.disabled {
    pointer-events: none; /* делаем ссылку некликабельной */
    cursor: default;  /* устанавливаем курсор в виде стрелки */
}

body {
  background-color: #ecedf1;
  font-family: Oswald;
  font-size: inherit;
  a, a:hover, a:focus {
    color: #00a0b0;
  }
}

.moduletable_main_search {
  float: right;
  background-color: #545553;
  padding: 20px;
  width: 100%;
  display: none;
  input {
    width: 95%;
    height: 50px;
    background-color: #ecedf1;
    border: none;
    float: right;
    color: #707070;
    font-family: Oswald;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
  }
}

.visible {
  display: block !important;
}

.visible_flex {
  display: flex !important;
}

.moduletable_breadcumbs {
  ul {
    padding-left: 0;
    li {
      margin-right: 3px;
    }
  }
}

.header-main {
  .main-logo {
    padding: 38px 71px 46px 0px;
    float: left;
  }

  .header-text {
    color: #00a0b0;
    font-family: 'Oswald';
    font-size: 2.5rem;
    float: left;
    height: 227px;
    padding-top: 78px;
    font-weight: 300;
  }

  .header-contacts {
    float: right;
    padding-top: 30px;
    text-align: right;
    p {
      margin-top: 7px;
      margin-bottom: 7px;
      &:nth-child(2) {
        margin-bottom: 21px;
      }
    }

    .header-contacts__phone a {
      color: #545553;
      font-family: 'Oswald', sans-serif;
      font-size: 1.5rem;
      letter-spacing: 1px;
      font-weight: 300;
    }

    button {
      width: 193px;
      height: 50px;
      border: 2px solid #00a1b1;
      background-color: #ecedf1;
      color: #545553;
      font-family: 'Oswald', sans-serif;
      font-size: 1.55rem;
      font-weight: 300;
      &:focus {
        outline: none;
      }
    }
  }

  .header-menu {
    height: 65px;
    opacity: 0.7;
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #545553;

    .moduletable_menu {
      max-width: 1170px;
      margin: 0 auto;
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 65px;

      li {
        list-style-type: none;
        float: left;
        text-align: left;

        a {
          color: #ffffff;
          font-family: Oswald;
          font-size: 1.875rem;
          font-weight: 300;
          padding-bottom: 7px;
          text-decoration: none;
          height: 65px;
          padding-bottom: 0;
          line-height: 65px;
          display: block;
          border-bottom: 5px solid transparent;

          &:hover {
            border-bottom: 5px solid white;

          }
        }
      }
    }
  }
}

.intro--background {
  background: url("../images/intro-bg.png") no-repeat;
  background-size: 100% 800px;
}

.question--background {
  background: url("../images/question-bg.png") no-repeat;
  margin-top: 50px;
  background-size: cover;
}

.question--background-black {
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 100px;
}

.content {
  .intro {
    overflow: hidden;
    padding-top: 150px;
    height: 800px;

    .intro__text {
      margin: 0 auto;
      color: #ffffff;
      font-family: Oswald;
      font-size: 3.75rem;
      font-weight: 300;
      text-transform: uppercase;
      width: 725px;
    }

    .intro__text--first {
      text-align: left;
    }

    .intro__text--second {
      text-align: right;
    }

    .intro__links-menu {
      ul {
        // display: flex;
        // align-items: stretch;
        // justify-content: space-between;
        padding-left: 0;
        li {
          width: 188px;
          height: 332px;
          padding-left: 20px;
          margin-top: 105px;
          border: 7px solid rgba(0, 0, 0, 0);

          &:hover {
            border: 7px solid #ffffff;
            background-color: rgba(141, 196, 231, 0.25);

            > a {
              text-decoration: underline;
            }
          }

          float: left;
          list-style-type: none;

          .img-wrapper {
            height: 158px;
            position: relative;
            margin-bottom: 30px;

            img {
              position: absolute;
              bottom: 0;
            }
          }

          a {
            color: #ffffff;
            font-family: Oswald;
            font-size: 1.56rem;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.moduletable_advantages {
  // margin-bottom: 130px;

  h3 {
    color: #545553;
    font-family: Oswald;
    font-size: 2.8125rem;
    font-weight: 300;
    text-align: center;
  }

  .advantages {
    ul {
      padding-left: 0;
      li {
        list-style-type: none;
        float: left;
        width: 25%;
        display: flex;
        flex-direction: column;

        .img-wrapper {
          text-align: center;
          margin-bottom: 15px;

          img {
            display: inline-block;
          }
        }

        a {
          color: #545553;
          font-family: Oswald;
          font-size: 1.56rem;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}

.moduletable_income {
  h3 {
    color: #545553;
    font-family: Oswald;
    font-size: 2.81rem;
    font-weight: 300;
    text-align: center;
  }

  .income {
    .income_wrapper {
      float: left;
      margin-top: 8px;
      width: 50%;
      overflow: hidden;
    }

    .income_wrapper--right {
      text-align: right;
    }

    .income_wrapper--text {
      background-color: #00a1b1;
      height: 310px;
      padding: 42px 51px 51px 52px;

      p {
        font-size: 1.25rem;
        margin: 0px 0 0 0;
        color: #ffffff;
        font-family: Oswald;
        font-weight: 300;
      }

      .income_wrapper__header {
        color: #ffffff;
        font-family: Oswald;
        font-size: 2.5rem;
        font-weight: 300;
        border-bottom: 2px solid #fff;
        padding-bottom: 15px;
        margin-bottom: 30px;

        .income_wrapper__header--bold {
          font-weight: 700;
        }
      }
    }
  }
}

.moduletable_question {
  min-height: min-content;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  padding-bottom: 20px;
  position: relative;

  h3 {
    color: #ffffff;
    font-family: Oswald;
    font-size: 2.81rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
  }

  .qlform_question {
    form {
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: center;
    }

    .custom_form_text {
      color: #ffffff;
      font-family: Oswald;
      font-size: 17px;
      font-weight: 300;
      text-align: center;
      flex-basis: 100%;
      margin: 10px 0 15px;
    }

    fieldset {
      border: none;
      float: left;
      flex-grow: 1;

      label {
        font-size: 0;
      }

      input {
        border: none;
        background-color: #ecedf1;
        color: #b7b7b7;
        font-family: Oswald;
        font-size: 1.56rem;
        font-weight: 300;
        padding-left: 10px;
        width: 100%;
        height: 3rem;
      }
    }

    .submit {
      .controls {
        button {
          padding: 2.8% 21%;
          border: 3px solid #ffffff;
          background-color: #00a1b1;
          color: #ffffff;
          font-family: Oswald;
          font-size: 1.875rem;
          font-weight: 500;
        }
      }
    }
  }
}

footer {
  .google_map, .footer__contacts {
    float: left;
    width: 50%;
    height: 427px;
  }

  .footer__contacts {
    padding: 60px 0 0 100px;
    background-color: #545553;
    font-family: Oswald;
    font-weight: 300;

    .footer__contacts--title {
      color: #00a1b1;
      font-size: 1.8rem;
      line-height: 23px;
    }

    p {
      color: #f3f6f7;
      font-size: 1.1rem;
      line-height: 25px;
    }

    .footer__media {
      .media_fb, .media_vk, .media_inst {
        float: left;
        margin-right: 20px;
        width: 33px;
        height: 32px;
      }
      .media_fb { background: url(../images/social-icons.png) no-repeat 0px 0px; }
      .media_vk { background: url(../images/social-icons.png) no-repeat -56px 0px; }
      .media_inst { background: url(../images/social-icons.png) no-repeat -113px 0px; }
    }
  }

  .copyright {
    width: 100%;
    float: left;
    height: 118px;
    background-color: #3c3c3a;
    color: #00a1b1;
    font-family: Oswald;
    font-size: 0.81rem;
    font-weight: 300;
    line-height: 23px;
    padding: 28px 0 0 208px;
  }
}
.popup-form {
  display: none;
  // display: flex;
  justify-content: center;
  span.popup_close_button {
    cursor: pointer;
    z-index: 9999;
    background:transparent url('../images/popup_close.png') left top no-repeat;
    width: 25px !important;
    height: 25px !important;
    position: absolute;
    top: 0px;
    right: 20px;
    margin-top: 25px;
  }

  h3 {
    color: #16a2b2;
    font-family: Oswald;
    font-size: 2.5rem;
    font-weight: 500;
    padding-left: 60px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  label {
    display: none;
  }
  input, textarea {
    border: none;
    margin-bottom: 16px;
    padding: 2px 30px 2px 15px;

    color: #707070;
    font-family: Oswald;
    font-size: 1.5rem;
    font-weight: 200;
    width: 382px;
  }

  input {
    height: 54px;
  }

  textarea {
    height: 153px;
  }

  fieldset {
    border: none;
    margin: 0 60px 0 60px;
    padding: 0;
  }

  button {
    border: none;
    width: 382px;
    height: 54px;
    background-color: #16a2b2;
    color: #ffffff;
    font-family: Oswald;
    font-size: 32px;
    font-weight: 500;
    padding-left: 80px;
    padding-right: 80px;
    margin-left: 60px;
    margin-bottom: 30px;
  }

  .popup-form-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: grey;
    opacity: 0.5;
  }

  .moduletable_popup-form {
    max-width: 502px;
    max-height: 706px;
    background-color: #ecedf1;
    margin-top: 5vh;
    // margin: -230px 0 0 -185px;
    position: fixed;
    // top: 50%;
    // left: 50%;
    z-index: 1001;
  }
}

#scrollUp {
  display: none;
  bottom: 20px;
  right: 95px;
  padding: 31px 31px;
  color: #fff;
  background: url(../images/scroll-up.png) no-repeat;
}

div[itemprop="articleBody"] {
  ul li {
    font-weight: 300;
  }

  p {
  font-weight: 300;
  }
}

